@import "../mainColors";
@import "../mixins";

.media-actions-header {
  position: sticky;
  top: 50px;
  background-color: white;
  z-index: 100;
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 -30px;
  box-shadow: 0px 1px 0 0 #b0b0b0;

  .add-buttons-wrapper {
    display: flex;
    flex-wrap: wrap;

    .link-button {
      background: transparent;
      color: black;
      font-weight: 600;

      .title {
        img {
          margin-right: 8px;
        }
      }
    }

    .file-uploader {
      margin-top: 4px;

      & + .link-button {
        margin-top: 4px;
      }
    }
  }

  .search-wrapper {
    position: relative;
    width: 40%;
    margin-left: 60px;

    .search-toggle-place {
      width: 100%;
      height: 32px;
      border: 1px solid $borderColor;
      border-radius: 4px;
      cursor: pointer;
      color: $borderColor;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 13px;
      line-height: 15px;

      .search-icon {
        margin: 0 10px;
        height: 14px;

        path {
          fill: $borderColor;
        }
      }

      .close-search {
        position: absolute;
        right: 10px;
        height: 12px;

        path {
          fill: $borderColor;
        }
      }
    }

    .search-box-wrapper {
      width: 120%;
      min-height: 250px;
      background-color: white;
      position: absolute;
      top: 0;
      left: -60px;
      box-shadow: 0px 0px 3px 1px #e5e5e5;
      border-radius: 4px;

      .close-icon {
        position: absolute;
        top: 12px;
        right: 10px;
        z-index: 10;
        cursor: pointer;
        height: 12px;
      }

      .search_box {
        flex-grow: 1;
        display: flex;
        flex-wrap: nowrap;
        margin-top: 4px;
        border-bottom: 1px solid $borderColor;

        .ant-tag {
          display: flex;
          align-items: center;
          margin-right: 0;
          margin-left: 10px;
          height: 28px;
          border-color: #1967d2;
          color: #1967d2;

          svg{
            path{
              fill: #1967d2;
            }
          }

          &:hover {
            background-color: #e8f0fe;
            //border-color: #e8f0fe;
          }
        }

        .search_btn {
          float: left;
          //border: 1px solid $borderColor;
          border-right: none;
          //border-radius: .25rem 0 0 .25rem;
          background-color: transparent;
          padding: 4px 0 4px 12px;
          display: flex;
          align-items: center;
          height: 33px;

          i {
            color: grey;
          }

          svg {
            path {
              fill: $borderColor;
            }
          }
        }

        .input-group {
          flex-grow: 1;
          margin-bottom: 0;

          input {
            //border-left: none !important;
            //border-top-left-radius: 0;
            //border-bottom-left-radius: 0;
            border: 0;
            height: 32px;
            padding: 6px 32px 6px 12px;

            &:focus {
              //border: 1px solid $borderColor;
            }
          }
        }
      }

      .search-actions {
        display: flex;
        padding: 10px;


        .search-action-item {
          background-color: transparent;
          display: flex;
          align-items: center;
          //background-color: #f6f4f4;
          border: 1px solid $borderColor;
          border-radius: 4px;
          padding: 4px 6px;
          margin-right: 10px;

          svg, i , img{
            margin-right: 4px;
            //height: 18px;
            //width: 20px;
          }

          &:hover {
            background-color: #e8f0fe;
            border-color: #1967d2;
          }
        }
      }

      .search-history {
        .history-item {
          display: flex;
          align-items: center;
          padding: 10px 4px;
          position: relative;

          svg {
            height: 16px;
            margin-right: 6px;
          }

          .close-icon {
            visibility: hidden;
            top: 50%;
            transform: translateY(-50%);
            height: 12px;
          }

          &:hover {
            background-color: #e8f0fe;
            font-weight: 500;
            cursor: pointer;

            .close-icon {
              visibility: visible;
            }
          }
        }
      }
    }
  }

  .right-wrapper {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    align-items: center;
    padding-right: 20px;


    .search-icon {
      margin-right: 8px;

      &.is-closed {
        display: none;
      }
    }

    .delete-btn {
      background-color: transparent;
      padding-right: 20px;
      border-right: 1px solid $borderColor;

    }

    .search-container {
      position: relative;
      display: none;
      width: 100%;
      margin-right: 8px;

      .search-close {
        position: absolute;
        right: 0;
        top: 8px;
        cursor: pointer;
        display: flex;
        align-items: center;
        animation-name: specialFadeInRight;
        animation-duration: 1000ms;
        animation-fill-mode: both;

        svg {
          width: 16px;
          height: 16px;
        }
      }

      &.active-search {
        display: flex;
        animation-name: specialFadeInRight;
        animation-duration: 250ms;
        animation-fill-mode: both;
        animation-delay: 50ms;

        .search-close {
          opacity: 1;
          visibility: visible;
        }
      }

      .input-group {
        margin: 0;
        position: relative;
        flex-grow: 1;

        input {
          padding-left: 25px;
          height: 100%;
          border: none;
          border-bottom: 1px solid $borderColor;
          border-radius: 0;
        }

        .search-icon {
          position: absolute;
          left: 0;
          top: 0;
          width: 40px;
          bottom: 0;
          display: flex;
          align-items: center;
        }
      }
    }

    .toggle-track-view {
      display: flex;
      margin-left: 24px;

      .list-view-button {
        margin: 0;
        background: none;
        border: 1px solid transparent;
        padding: 6px 10px;

        &.selected {
          border: 1px solid $borderColor;
        }
      }
    }
  }

  @keyframes specialFadeInRight {
    from {
      opacity: 0;
      transform: scale(0.9);
    }

    to {
      opacity: 1;
      transform: scale(1);
    }
  }

  @media all and (max-width: 1200px) {
    margin: 0 -15px;
  }

  @media all and (max-width: 800px) {
    margin: 0 -8px;
  }
}
