@import '../mainColors';
@import '../mixins';

.add-file-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  color: black;
  font-weight: 600;
  padding: 6px 16px;
  cursor: pointer;

  img {
    margin-right: 8px;
  }
}

.add-file-dropdown-card {
  &:before {
    top: -10px;
    left: 105px;
    @media all and (max-width: 550px) {
      right: 10px;
    }
  }

  &:after {
    top: -12px;
    left: 103px;
    @media all and (max-width: 550px) {
      right: 8px;
    }
  }

  @media all and (max-width: 550px) {
    left: 10px;
  }
}

.add-file-dropdown-card {
  width: 350px;
  background-color: white;
  -webkit-box-shadow: #ddd 0px 0px 3px 1px;
  -moz-box-shadow: #eee 0px 0px 3px 1px;
  box-shadow: #e5e5e5 0px 0px 3px 1px;
  border-radius: 4px;

  &:before {
    content: "";
    border-bottom: 10px solid #fff;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
    position: absolute;
    z-index: 10;
  }

  &:after {
    content: "";
    border-bottom: 12px solid #f1f1f1;
    border-right: 12px solid transparent;
    border-left: 12px solid transparent;
    position: absolute;
    z-index: 9;
  }

  .type-select-icons {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    .dropdown-card-img-wrapper {
      margin: 35px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      cursor: pointer;
      img {
        height: 34px;
        width: 34px;
        margin-bottom: 4px;
        object-fit: cover;
        cursor: pointer;
      }
      span {
        width: 120px;
        color: #7D7E85;
        font-size: 12px;
        font-weight: 600;
        line-height: 16px;
      }
    }
  }

  .form-wrapper {
    padding: 30px 30px 10px;
  }

  .input-group {
    margin-bottom: 6px;

    input {
      padding-left: 40px;
    }

    position: relative;

    .placeholder-img {
      position: absolute;
      width: 24px;
      top: 5px;
      left: 10px;
      opacity: .5;
    }
  }

  .folder-name-broken {
    height: 25px;
    margin-bottom: 6px;
    span {
      color: red;
    }
  }

  .ant-tabs-tab {
    margin: 0;
  }
  .buttons-wrapper {
    border-top:1px solid $borderColor;
    padding: 15px 30px;
    display: flex;
    justify-content: flex-end;
    .link-button {
      padding: 6px 16px;
      transition: .3s;
      margin-left: 8px;

      &:hover {
        -webkit-box-shadow: 0px 0px 6px 0px rgba(180, 180, 180, 1);
        -moz-box-shadow: 0px 0px 6px 0px rgba(180, 180, 180, 1);
        box-shadow: 0px 0px 6px 0px rgba(180, 180, 180, 1);
      }
    }
  }
}

.add-file-dropdown {
  z-index: 100;

  &.in-selector-modal {
    z-index: 1001;
  }
}
