@import "../mainColors";

.upload-box{
  padding: 0!important;
  .ant-notification-notice-content{
    .ant-notification-notice-message{
      font-size: 13px;
      padding: 10px 16px;
      background-color: $brandColor;
      width: 100%;
      color: white;
      margin-bottom: 0;
      span{
        display: flex;
      }
      .upload-icon{
        margin-right: 6px;
        path{
          fill: white;
        }
      }
    }
    .ant-notification-notice-description{
      padding: 6px 16px 10px 16px;
      .progress {
        .file-route{
          cursor: pointer;
        }
      }
      .ant-progress-line{
        font-size: 13px;
      }
    }
  }
  .ant-notification-notice-close {
    top: 10px;
    right: 16px;
    .anticon-close {
      svg {
        path {
          fill: white;
        }
      }
    }
  }
}
