@import "../mainColors";
@import "../mixins";

.admin-files {
  //min-height: calc(100vh - 130px);
  .fetching-loader {
    position: absolute;
    opacity: 0.5;
    top:0;
    left:0;
    bottom:0;
    right:0;
    display: flex;
    align-items: center;
    justify-content: center;
  }


  .media-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin: 20px -10px 0;

    &.list-view {
      margin: 0 0 0 -30px;
      width: calc(100% + 60px);
      display: table;
      border-collapse: collapse;

      .list-header {
        display: table-header-group;
        vertical-align: middle;
        border: 1px solid $borderColor;
        border-left: none;

        > span {
          display: table-cell;
          vertical-align: inherit;
          font-weight: 600;
          padding: 5px 0;

          &:not(:nth-child(2)) {
            border-left: 1px solid $borderColor;
            padding-left: 10px;
          }
          &:first-child {
            padding-left: 20px;
            border-left: none;
          }
        }
      }
    }
  }
}

.media-title {
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;

  &.small-title {
    //width: calc(100% - 800px)
  }

  @include custom-scrollbar-x;

  .path-item {
    cursor: pointer;
    transition: all 0.2s;
    font-size: 24px;
    font-weight: 500;
    color: #344563;
    letter-spacing: normal;
    &:hover {
      color: $brandColor;
    }
  }

}

@media screen and (max-width: 1200px) {
  .admin-files {
    .media-wrapper {
      &.list-view {
        margin: 0 0 0 -15px;
        width: calc(100% + 30px);
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .admin-files {
    .media-wrapper {
      &.list-view {
        margin: 0 0 0 -8px;
        width: calc(100% + 16px);
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .admin-files {
    .media-wrapper {
      &.list-view {
        margin: 0 0 0 -8px;
        width: calc(100% + 16px);
      }
    }
  }
}
