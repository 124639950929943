.details-header {
  margin: 20px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;

  .right-side {
    display: flex;
    flex-direction: column;
    text-align: left;
    align-items: flex-end;


    .right-part-item {
      margin-top: 5px;
      color: black;
      font-size: 16px;
      font-weight: 600;
    }
  }

  .left-part {
    display: flex;
    flex-direction: column;
    font-size: 16px;

    div {
      margin-bottom: 10px;
    }

    .text-bold {
      font-weight: bold;
      color: rgba(0, 0, 0, 0.85);
      padding-right: 10px;
    }

    .text-grey {
      color: rgba(0, 0, 0, 0.6);
      font-weight: 500;
    }
  }
}
