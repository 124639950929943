@import '../mainColors';
@import '../mixins';

.media-selector-modal {
  height: 90vh;
  width: 90vw;
  padding: 0 20px 20px;

  .media-selector-modal-inner {
    height: 100%;
    width: 100%;
    position: relative;
  }

  .selector-modal-header {
    padding-top: 20px;
    height: 60px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .input-group {
      margin: 0 20px 0 0;
      position: relative;
      width: 300px;

      input {
        padding-right: 40px;
        height: 100%;
      }

      .search-icon {
        position: absolute;
        right: 0;
        top: 0;
        width: 40px;
        bottom: 0;
        display: flex;
        align-items: center;
        padding-left: 10px;
      }
    }

    .selector-modal-title {
      .path-item {
        cursor: pointer;
        transition: all 0.2s;

        &:hover {
          color: $brandColor;
        }
      }

      h1 {
        font-size: 24px;
        font-weight: 500;
        text-transform: uppercase;
        margin: 0;
        color: black;
      }
    }

    .selector-modal-closing-btn {
      position: absolute;
      top: 7px;
      right: -10px;
      padding: 0;
      height: 20px;
      background: none;
      border: none;
      cursor: pointer;

      i {
        color: #a5afb8;
        font-size: 20px;
      }

      &:focus {
        outline: none;
      }
    }
  }

  .action-btns {
    display: flex;
    justify-content: space-between;
    position: relative;
    height: 40px;
    padding-bottom: 5px;
    .right-wrapper{
      display: flex;
    }
    .toggle-track-view {
      display: flex;

      .list-view-button {
        margin: 0 !important;
        background: none;
        border: 1px solid transparent;
        padding: 6px 10px;
        img {
          margin-right: 0;
        }

        &.selected {
          border: 1px solid $borderColor;
        }
      }
    }

    .link-button {
      margin-right: 12px;
      background: transparent;
      color: black;
      font-weight: 600;
      img {
        margin-right: 8px;
      }
    }
  }
  .medias{
    width: 100%;
    overflow-y: auto;
    height: calc(100% - 145px);
    .media-items-wrapper {
      display: flex;
      flex-wrap: wrap;
    }
  }


  .choose-button {
    position: absolute;
    bottom: 0;
    right: 20px;
    height: 35px;
    background-color: white;
    display: flex;
    justify-content: flex-end;

  }
}
