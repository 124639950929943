@import "../mainColors";

.product-card {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 230px;
  text-align: center;
  background-color: white;
  color: black;
  border-radius: 8px;
  padding: 16px 12px;
  margin: 20px 20px 20px 0;
  border-bottom: 1px solid $borderColor;
  box-shadow: 0px 1px 4px 0px rgba(9, 30, 66, 0.1);
  transition: .3s ease;

  &:hover {
    box-shadow: 0px 5px 12px 0px $borderColor;
  }

  &.isSelected {
    opacity: 0.5;
  }

  .check-mark {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1;
    opacity: 0;
    transition: opacity 0.2s;

    &.show {
      opacity: 1;
    }

    img {
      width: 20px;
      height: 20px;
    }
  }

  .more {
    font-size: 22px;
    position: absolute;
    top: 12px;
    right: 5px;
  }

  .hidden-product-icon {
    position: absolute;
    top: 12px;
    left: 8px;
  }

  .product-img {
    width: 120px;
    height: 120px;
    object-fit: cover;
  }

  .product-title {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: 17px;
    font-weight: 600;
    padding: 12px 0px;
    height: 64px;
  }

  .old-price {
    border-top: 1px solid $borderColor;
    height: 26px;
    text-align: left;
    font-size: 14px;
    font-weight: bold;
    color: #999;
    text-decoration: line-through;
    margin-top: 20px;
    padding-top: 6px;
  }

  .discounted-price {
    text-align: left;
    font-size: 16px;
    font-weight: bold;
  }

  .details-btn {
    position: absolute;
    bottom: 18px;
    right: 12px;

    .link-button {
      padding: 3px 6px;
    }
  }

  .delete-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1;
    width: 25px;
    height: 25px;
    cursor: pointer;
    padding: 3px 6px;
    border-radius: 5px;
    -webkit-transition: all .2s linear;
    transition: all .2s linear;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;

    i {
      color: #F24134;
      font-size: 19px;
    }

    &:hover {
      background: #F24134;

      i {
        color: white;
        font-size: 19px;
      }
    }

  }
}
